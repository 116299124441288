import React from 'react'

const WhatWeDo = () => {
  return (
    <>
        <section className="we_do-wrapper overflow-hidden section-padding">
    {/* <!-- line 4 --> */}
    <div className="line-1"><span></span></div>
    <div className="line-2"><span></span></div>
    <div className="line-3"><span></span></div>
    <div className="line-4"><span></span></div>

    <div className="container">
        <div className="row">
            <div className="col-lg-6">
                <div className="single-wi_do-left wow fadeInUp animated" data-wow-delay="300ms">
                    <div className="section-title p-0">
                        <h6> <span className="title-arrow"><i className="fa-solid fa-arrow-right"></i></span>What we do!</h6>
                        <h2>Driving Digital Excellence with Tailored IT Solutions</h2>
                    </div>
                    <p>At Mejona Technology, we excel in providing tailored IT solutions that drive digital excellence. Our expertise spans a wide range of services designed to elevate your business to new heights. </p>
                    <div className="row ">
                        
                        <div className="col-sm-6">
                            <div className="icon-text">
                                <div className="icons">
                                    <img src="assets/img/svg-icon/website.svg" alt=""/>
                                </div>
                                <h4>Web Development</h4>   
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="icon-text">
                                <div className="icons">
                                    <img src="assets/img/svg-icon/software.svg" alt=""/>
                                </div>
                                <h4>Sotware Development</h4>  
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="icon-text">
                                <div className="icons">
                                    <img src="assets/img/svg-icon/application.svg" alt=""/>
                                </div>
                                <h4>Mobile App Development</h4>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="icon-text">
                                <div className="icons">
                                    <img src="assets/img/svg-icon/finger.svg" alt=""/>
                                </div>
                                <h4>Digital Advertising and marketing</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="single-wi_do-right ps-2 ps-md-5 mt-md-0">
                    <div className="bg-center bg-cover we_do-bg">
                        <img src="assets/img/job-office.jpg" alt="we_do_img"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
   </section>
    </>
  )
}

export default WhatWeDo