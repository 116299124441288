import React, { useState, useEffect } from 'react';

const Preloader = () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const handleLoad = () => {
      setLoaded(true);
    };

    const timeoutId = setTimeout(() => {
      setLoaded(true);
    }, 5000);

    window.addEventListener('load', handleLoad);
    document.addEventListener('DOMContentLoaded', handleLoad);

    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('load', handleLoad);
      document.removeEventListener('DOMContentLoaded', handleLoad);
    };
  }, []);

  return (
    <div id="preloader" className={`preloader ${loaded ? 'loaded' : ''}`}>
      <div id="preloader" className="preloader">
        <div className="animation-preloader">
          <div className="spinner"></div>
          <div className="txt-loading">
            <span data-text-preloader="M" className="letters-loading">
              M
            </span>
            <span data-text-preloader="e" className="letters-loading">
              e
            </span>
            <span data-text-preloader="j" className="letters-loading">
              j
            </span>
            <span data-text-preloader="o" className="letters-loading">
              o
            </span>
            <span data-text-preloader="n" className="letters-loading">
              n
            </span>
            <span data-text-preloader="a" className="letters-loading">
              a
            </span>
          </div>
          <p className="text-center">Loading</p>
        </div>
        <div className="loader">
          <div className="row">
            <div className="col-3 loader-section section-left">
              <div className="bg"></div>
            </div>
            <div className="col-3 loader-section section-left">
              <div className="bg"></div>
            </div>
            <div className="col-3 loader-section section-right">
              <div className="bg"></div>
            </div>
            <div className="col-3 loader-section section-right">
              <div className="bg"></div>
            </div>
          </div>
        </div>
      </div>

      <style>{`
        .preloader {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 9999;
          background-color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .preloader.loaded {
          display: none;
        }

        /* Add any additional CSS styles for the preloader elements */
      `}</style>
    </div>
  );
};

export default Preloader;