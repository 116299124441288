import React from "react";

const MainSection = () => {
  const style = {
    padding: "10px",
    listStyleType: "",
    important: "true",
  };
  return (
    <>
      <section className="blog-wrapper overflow-hidden section-top blog-1">
        <div className="line-1">
          <span></span>
        </div>
        <div className="line-2">
          <span></span>
        </div>
        <div className="line-3">
          <span></span>
        </div>
        <div className="line-4">
          <span></span>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="row">
                <div className="col-md-12 mt-30">
                  <div className="single_blog bg-transparent"><section id="contactForm">
    <h3>Information Collection and Use</h3>
    <p>We may collect personal information such as your name, email address, and contact details when you voluntarily submit them through our website forms. We use this information to respond to your inquiries and provide you with requested information.</p>
</section>

<section id="logData">
    <h3>Log Data</h3>
    <p>Like many website operators, we collect information that your browser sends whenever you visit our website. This may include information such as your computer's Internet Protocol (IP) address, browser type, browser version, the pages of our website that you visit, the time and date of your visit, and other statistics.</p>
</section>

<section id="cookies">
    <h3>Cookies</h3>
    <p>Our website may use cookies to enhance your browsing experience. Cookies are small text files that are placed on your computer or mobile device when you visit a website. You can disable cookies through your browser settings, but please note that certain features of the website may not function properly without cookies.</p>
</section>

<section id="security">
    <h3>Information Security</h3>
    <p>We implement security measures to protect your personal information against unauthorized access, alteration, disclosure, or destruction. However, please be aware that no method of transmission over the Internet or electronic storage is 100% secure.</p>
</section>

<section id="changes">
    <h3>Changes to this Privacy Policy</h3>
    <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the updated Privacy Policy on this page.</p>
</section>

<section id="contactUs">
    <h3>Contact Us</h3>
    <p>If you have any questions or concerns about this Privacy Policy, please contact us at <a href="mailto:admin@mejona.in">admin@mejona.in</a>.</p>
</section>
    </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="row">
                <div className="col-12 mt-30">
                  <div className="blog-right">
                    {/* <h4>Search Here</h4>
                    <div className="newsletter_box about-right">
                      <form action="#">
                        <input
                          type="email"
                          placeholder="Enter Your Keyword..."
                          required=""
                        />
                        <button className="search-btn" type="submit">
                          <i className="fa-solid fa-magnifying-glass"></i>
                        </button>
                      </form>
                    </div> */}
                    <div className="service-list">
                      <h4>JUMP TO</h4>
                      <button>
                        <span className="icon">
                          <i className="fa-solid fa-angle-right"></i>
                        </span>{" "}
                        Log Data
                      </button>
                      <button>
                        <span className="icon">
                          <i className="fa-solid fa-angle-right"></i>
                        </span>
                        Cookies
                      </button>
                      <button>
                        <span className="icon">
                          <i className="fa-solid fa-angle-right"></i>
                        </span>
                        Information Security
                      </button>
                      <button>
                        <span className="icon">
                          <i className="fa-solid fa-angle-right"></i>
                        </span>
                        Changes To This Privacy Policy
                      </button>
                      <button>
                        <span className="icon">
                          <i className="fa-solid fa-angle-right"></i>
                        </span>
                        Contact Us
                      </button>
                    
                    </div>
                    <div className="icons-element">
                      <h4>Social icon</h4>
                      <div className="icons">
                       
                       <a href="https://www.instagram.com/mejona_technology">
                         <i className="fa-brands fa-instagram"></i>
                       </a>
                       <a href="https://www.linkedin.com/company/mejona">
                         <i className="fa-brands fa-linkedin"></i>
                       </a>
                       <a href="https://facebook.com/Mejona.technology">
                         <i className="fa-brands fa-facebook"></i>
                       </a>
                       <a href="https://www.youtube.com/@Tech_Mejona">
                         <i className="fa-brands fa-youtube"></i>
                       </a>
                     </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 mt-30">
                  <div
                    className="blog-bottom-right bg-center bg-cover"
                    style={{ backgroundImage: "url(assets/img/video.png)" }}
                  >
                    <div className="content">
                      <h4>Innovate with confidence</h4>
                      <span>We deliver tech solutions that fuel growth.</span>
                      <h3>25+</h3>
                      <strong>Satisfied Clients</strong>
                      <a href="discover" className="theme-btn style-1">
                        Discover More +
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MainSection;
