import React from 'react'

const Hero = () => {
  return (
    <>
        
<section class="hero-wrapper hero-1 hero-3 hero-4 hero-border overflow-hidden">
        <div class="hero-widget">
            <div class="hero-single-slider bg-center bg-cover" style={{backgroundImage: 'url(assets/img/hero/breadcrem.jpg)'}}>
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-12">
                            <div class="hero-content">
                                <h1 class="breadcrumb__title">Privacy Policy</h1>
                                <div class="bread_c">
                                    <p class="breadcrumb">
                                       <a href="/">Home /&nbsp;</a>  privacy
                                    </p>
                                 </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="breadcrumb-shape wow fadeInUpBig">
                    <img src="assets/img/svg-icon/video-shape.svg" alt=""/>
                </div>
                <div class="shape-left wow fadeInLeft">
                    <img src="assets/img/shap/shap.png" alt="shape"/>
                </div>
                <div class="shape-bottom-2 wow fadeInUpBig">
                    <img src="assets/img/shap/shap-2.png" alt="shape"/>
                </div>
                <div class="breadcrumb-right-element wow fadeInRightBig">
                    <img src="assets/img/svg-icon/right-2.svg" alt=""/>
                </div>
                <div class="top-element wow fadeInDown">
                    <img src="assets/img/svg-icon/shape-3.svg" alt="" />
                </div>
              
            </div>
        </div>
    </section>
    </>
  )
}

export default Hero