import React from 'react'

const ContactMessage = () => {
  return (
    <>
         <section className="cta-wrapper overflow-hidden wow fadeInUp animated cta-wrapper-home-1" data-wow-delay="300ms">

<div className="cta-widget">
    <div className="container">
        <div className="row align-items-center">
            <div className="col-md-8">
                <div className="cta-left">
                    <h2>Crafting Tailored Solutions to Address Your<span> Business Challenges</span></h2>
                </div>
            </div>
            <div className="col-md-4 text-end">
                <a href="contact" className="theme-btn style-1">Contact Now +</a>
            </div>
        </div>
    </div>
    <div className="dot-element-1">
        <img src="assets/img/svg-icon/dot.svg" alt=""/>
    </div>
    <div className="dot-element-2">
        <img src="assets/img/svg-icon/dot.svg" alt=""/>
    </div>
    <div className="line-element-1">
        <img src="assets/img/svg-icon/line.svg" alt=""/>
    </div>
    <div className="line-element-2">
        <img src="assets/img/svg-icon/line-2.png" alt=""/>
    </div>
    <div className="vector-img">
        <img src="assets/img/svg-icon/vector-47.svg" alt=""/>
    </div>
    <div className="sercle-1"></div>
    <div className="sercle-2"></div>
</div>

</section>
    </>
  )
}

export default ContactMessage