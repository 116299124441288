import React from "react";

const WhatWeDo = () => {
  return (
    <>
      <section
        class="we_do-wrapper we_do-2 section-padding overflow-hidden bg-center bg-cover"
        style={{ backgroundImage: "url(assets/img/software-developer.jpg)" }}
      >
        <div class="container">
          <div class="row align-items-end">
            <div
              class="col-lg-6 wow fadeInLeft animated"
              data-wow-delay="300ms"
            >
              <div class="single-wi_do-left">
                <div class="section-title p-0">
                  <h6>
                    {" "}
                    <span class="title-arrow title-arrow-sp">
                      <i class="fa-solid fa-arrow-right"></i>
                    </span>{" "}
                    What we do!
                  </h6>
                  <h2>
                    Elevating Businesses through Comprehensive IT Solutions
                  </h2>
                </div>
                <p>
                  At Mejona Technology LLP, our commitment is to revolutionize
                  the digital landscape for businesses, providing transformative
                  solutions that span a wide spectrum of IT services. Explore
                  the core aspects of what sets us apart:{" "}
                </p>
               
              </div>
            </div>

            <div
              class="col-lg-6 wow fadeInRight animated"
              data-wow-delay="300ms"
            >
             <p>
                  In the realm of Custom Web Development, we embark on a journey
                  to understand the essence of your brand. Our team crafts
                  dynamic, user-centric websites that go beyond functionality,
                  resonating with your audience on a deeper level. Whether
                  you're launching a new brand or revamping an existing online
                  presence, our custom web development services are tailored to
                  meet your unique business goals.
                </p><br/>
              <p>
                When it comes to Innovative App Development, we unleash the
                potential of your unique ideas. Our skilled developers leverage
                the latest technologies to craft intuitive and engaging mobile
                applications across diverse platforms. From concept to
                deployment, we ensure that your brand stands out in the
                competitive app market, delivering solutions that captivate and
                inspire.{" "}
              </p>
              <p>
                Explore our diverse set of offerings through the following list:
              </p>
            </div>
          </div>
          <div class="row mt-30">
            <div
              class="col-md-4 col-sm-6 mt-30 wow fadeInUp animated"
              data-wow-delay="300ms"
            >
              <div class="icon-text">
                <div class="icons">
                  <img style={{width:'60px'}} src="assets/img/expert.png" alt="" />
                </div>
                <h4>
                Expert IT Consulting
                </h4>
              </div>
            </div>
            <div
              class="col-md-4 col-sm-6 mt-30 wow fadeInUp animated"
              data-wow-delay="300ms"
            >
              <div class="icon-text">
                <div class="icons">
                  <img style={{width:'60px'}} src="assets/img/custom.png" alt="" />
                </div>
                <h4>
                Custom Web Development
                </h4>
              </div>
            </div>
            <div
              class="col-md-4 col-sm-6 mt-30 wow fadeInUp animated"
              data-wow-delay="300ms"
            >
              <div class="icon-text">
                <div class="icons">
                  <img style={{width:'60px'}} src="assets/img/innovation.png" alt="" />
                </div>
                <h4>
                Innovative App Development
                </h4>
              </div>
            </div>
            <div
              class="col-md-4 col-sm-6 mt-30 wow fadeInUp animated"
              data-wow-delay="300ms"
            >
              <div class="icon-text">
                <div class="icons">
                  <img style={{width:'60px'}}  src="assets/img/planning.png" alt="" />
                </div>
                <h4>
                Strategic Digital Marketing
                </h4>
              </div>
            </div>
            <div
              class="col-md-4 col-sm-6 mt-30 wow fadeInUp animated"
              data-wow-delay="300ms"
            >
              <div class="icon-text">
                <div class="icons">
                  <img style={{width:'60px'}} src="assets/img/quality.png" alt="" />
                </div>
                <h4>
                Quality Assurance Excellence
                </h4>
              </div>
            </div>
            <div
              class="col-md-4 col-sm-6 mt-30 wow fadeInUp animated"
              data-wow-delay="300ms"
            >
              <div class="icon-text">
                <div class="icons">
                  <img style={{width:'60px'}} src="assets/img/maintainance.png" alt="" />
                </div>
                <h4>
                Dedicated Maintenance and Support
                </h4>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhatWeDo;
