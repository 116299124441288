import React from 'react'
import Header from './header'
import Hero from './contact_components/hero'
import Contact_section from './contact_components/contact_section'
import BusinessPartner from './homepage_components/businesss_partner'
import Footer from './footer'
import Preloader from './preloader'

const Contact = () => {
  return (
    <>
    {/* <Preloader/> */}
        <Header/>
        <Hero/>
        <Contact_section/>
        <BusinessPartner/>
        <Footer/>
    </>
  )
}

export default Contact