import React from 'react';
import Header from './header.jsx';
import Hero from './services_components/hero.jsx';
import Feature from './services_components/feature.jsx';
import WhatWeDo from './services_components/what_we_do.jsx';
import Footer from './footer.jsx';import Preloader from './preloader.jsx';
;

const Service = () => {
  return (
  <>
  {/* <Preloader/> */}
   <Header/>
    <Hero/>
    <Feature/>
    <WhatWeDo/>
    <Footer/>
  </>
 
  )
}

export default Service