import React from 'react'
import { Helmet } from 'react-helmet';
import Header from './header'
import Hero from './privacy_components/hero'
import MainSection from './privacy_components/MainSection'
import Footer from './footer'

const Privacy = () => {
  return (
    <>
     <Helmet>
     <meta name="robots" content="noindex,nofollow" />
        {/* Add more meta tags as needed */}
      </Helmet>
    <Header/>
    <Hero/>
    <MainSection/>
    <Footer/>
    </>
  )
}

export default Privacy