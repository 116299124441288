import React from "react";

const Hero_section = () => {
  return (
    <>
      {/* <!-- hero section start --> */}
      <section className="hero-wrapper hero-1 overflow-hidden">
        {/* <!-- line 4 --> */}
        <div className="line-1">
          <span></span>
        </div>
        <div className="line-2">
          <span></span>
        </div>
        <div className="line-3">
          <span></span>
        </div>
        <div className="line-4">
          <span></span>
        </div>
        <div className="hero-slider-wrapper owl-carousel">
          <div
            className="hero-single-slider bg-center bg-cover"
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/assets/img/hero/bg-hero.jpg)`,
            }}
          >
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-10">
                  <div className="hero-content">
                    <h1 data-animation="fadeInRight" data-delay="0.4s">
                      Your Trusted Technology Partner
                    </h1>
                    <p data-animation="fadeInUp" data-delay="0.8s">
                      We deliver customized software solutions that help
                      businesses of all sizes and industries grow and succeed.
                    </p>
                    <a
                      data-animation="fadeInUp"
                      data-delay="1s"
                      href="about"
                      className="theme-btn"
                    >
                      Discover More +
                    </a>
                  </div>
                </div>
                <div className="col-md-2 text-end">
                  <div className="video-play-btn">
                    <a
                      href="https://www.youtube.com/watch?v=4tt0eYg7NKQ"
                      className="play-video popup-video"
                    >
                      <i className="fa-solid fa-play"></i>
                    </a>
                    <div
                      className="shape"
                      data-animation="fadeInUp"
                      data-delay="1.5s"
                    >
                      <img src="assets/img/svg-icon/video-shape.svg" alt=" " />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="right-element"
              data-animation="fadeInRight"
              data-delay="1.5s"
            >
              <img src="assets/img/svg-icon/right-2.svg" alt=" " />
            </div>
            <div
              className="top-element"
              data-animation="fadeInUp"
              data-delay="1000.5s"
            >
              <img src="assets/img/svg-icon/shape-3.svg" alt=" " />
            </div>
            <div
              className="bottom-element"
              data-animation="fadeInUp"
              data-delay="1.5s"
            >
              <img src="assets/img/svg-icon/shape-2.svg" alt=" " />
            </div>
          </div>
          <div
            className="hero-single-slider bg-center bg-cover"
            style={{ backgroundImage: "url(assets/img/hero/bg2.jpg)" }}
          >
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-10">
                  <div className="hero-content">
                    <h1 data-animation="fadeInRight" data-delay="0.4s">
                      Custom Software Solutions for Growth and Success
                    </h1>
                    <p data-animation="fadeInUp" data-delay="0.8s">
                      We specialize in web development, mobile app development,
                      software consulting, and digital marketing.
                    </p>
                    <a
                      data-animation="fadeInUp"
                      data-delay="1s"
                      href="about"
                      className="theme-btn"
                    >
                      Discover More +
                    </a>
                  </div>
                </div>
                <div className="col-md-2 text-end">
                  <div className="video-play-btn">
                    <a
                      href="https://www.youtube.com/watch?v=4tt0eYg7NKQ"
                      className="play-video popup-video"
                    >
                      <i className="fa-solid fa-play"></i>
                    </a>
                    <div
                      className="shape"
                      data-animation="fadeInUp"
                      data-delay="1.5s"
                    >
                      <img src="assets/img/svg-icon/video-shape.svg" alt=" " />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="right-element"
              data-animation="fadeInRight"
              data-delay="1.5s"
            >
              <img src="assets/img/svg-icon/right-2.svg" alt=" " />
            </div>
            <div
              className="top-element"
              data-animation="fadeInUp"
              data-delay="1.5s"
            >
              <img src="assets/img/svg-icon/shape-3.svg" alt=" " />
            </div>
            <div
              className="bottom-element"
              data-animation="fadeInUp"
              data-delay="1.5s"
            >
              <img src="assets/img/svg-icon/shape-2.svg" alt=" " />
            </div>
          </div>
          <div
            className="hero-single-slider bg-center bg-cover"
            style={{ backgroundImage: 'url("assets/img/hero/bg1.jpg")' }}
          >
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-10">
                  <div className="hero-content">
                    <h1 data-animation="fadeInRight" data-delay="0.4s">
                      Driving Innovation Through Technology
                    </h1>
                    <p data-animation="fadeInUp" data-delay="0.8s">
                      We use the latest technologies and best practices to
                      create high-quality, reliable, and scalable software
                      solutions that help our clients achieve their goals.
                    </p>
                    <a
                      data-animation="fadeInUp"
                      data-delay="1s"
                      href="about"
                      className="theme-btn"
                    >
                      Discover More +
                    </a>
                  </div>
                </div>
                <div className="col-md-2 text-end">
                  <div className="video-play-btn">
                    <a
                      href="https://www.youtube.com/watch?v=4tt0eYg7NKQ"
                      className="play-video popup-video"
                    >
                      <i className="fa-solid fa-play"></i>
                    </a>
                    <div
                      className="shape"
                      data-animation="fadeInUp"
                      data-delay="1.5s"
                    >
                      <img src="assets/img/svg-icon/video-shape.svg" alt=" " />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="right-element"
              data-animation="fadeInRight"
              data-delay="1.5s"
            >
              <img src="assets/img/svg-icon/right-2.svg" alt=" " />
            </div>
            <div
              className="top-element"
              data-animation="fadeInUp"
              data-delay="1.5s"
            >
              <img src="assets/img/svg-icon/shape-3.svg" alt=" " />
            </div>
            <div
              className="bottom-element"
              data-animation="fadeInUp"
              data-delay="1.5s"
            >
              <img src="assets/img/svg-icon/shape-2.svg" alt=" " />
            </div>
          </div>
        </div>
        {/* <div className="text-marquee_home1">
          <div className="scrolling-text marquee">
            * Web development * Mobile app development * Digital marketing * Cloud computing * Enterprise software development *
          Custom software development * Advertising * Maintenance * Design * Business *
            Marketing * Consultant
          </div>
        </div> */}
      </section>
      {/* <!-- hero section end --> */}

      {/* <!-- service section start --> */}
      <section className="service-wrapper service-2 overflow-hidden section-top">
        {/* <!-- line 4 --> */}
        <div className="line-1">
          <span></span>
        </div>
        <div className="line-2">
          <span></span>
        </div>
        <div className="line-3">
          <span></span>
        </div>
        <div className="line-4">
          <span></span>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <div
                className="section-title text-center wow fadeInUp animated"
                data-wow-delay="300ms"
              >
                <h6 className="text-center">
                  {" "}
                  <span className="title-arrow">
                    <i className="fa-solid fa-arrow-right"></i>
                  </span>{" "}
                  Our Features
                </h6>
                <h2>Let’s Discover Our Service Features Charter</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className="col-lg-3 col-md-4 col-sm-6 wow fadeInUp animated"
              data-wow-delay="300ms"
            >
              <div className="single-service-widget">
                <div className="single_service mt-30">
                  <div className="card-img">
                    <img src="assets/img/svg-icon/magnifier.svg" alt=" " />
                  </div>
                  <p>Mejona is committed to transparency and communication with its clients throughout the software development process.</p>
                  <h5>Transparency</h5>
                </div>
                <div
                  className="bg-center bg-cover service-bg-item"
                  style={{
                    backgroundImage: "url(assets/img/service/transparency.jpg)",
                  }}
                ></div>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-4 col-sm-6 wow fadeInUp animated"
              data-wow-delay="500ms"
            >
              <div className="single-service-widget">
                <div className="single_service mt-30">
                  <div className="card-img">
                    <img src="assets/img/svg-icon/flexibility.svg" alt=" " />
                  </div>
                  <p>Mejona offers a variety of flexible engagement models to meet the needs of its clients.</p>
                  <h5>Flexible engagement models</h5>
                </div>
                <div
                  className="bg-center bg-cover service-bg-item"
                  style={{
                    backgroundImage: "url(assets/img/service/flexibility.jpg)",
                  }}
                ></div>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-4 col-sm-6 wow fadeInUp animated"
              data-wow-delay="700ms"
            >
              <div className="single-service-widget">
                <div className="single_service mt-30">
                  <div className="card-img">
                    <img src="assets/img/svg-icon/quality.svg" alt=" " />
                  </div>
                  <p>Mejona is committed to delivering high-quality software solutions that are reliable, scalable, and easy to use.</p>
                  <h5>Quality</h5>
                </div>
                <div
                  className="bg-center bg-cover service-bg-item"
                  style={{
                    backgroundImage: "url(assets/img/service/quality.jpg)",
                  }}
                ></div>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-4 col-sm-6 wow fadeInUp animated"
              data-wow-delay="1000ms"
            >
              <div className="single-service-widget">
                <div className="single_service mt-30">
                  <div className="card-img">
                    <img src="assets/img/svg-icon/satisfaction.svg" alt=" " />
                  </div>
                  <p>Mejona is dedicated to providing its clients with the best possible service and support.</p>
                  <h5>Customer satisfaction</h5>
                </div>
                <div
                  className="bg-center bg-cover service-bg-item"
                  style={{
                    backgroundImage: "url(assets/img/service/satisfaction.jpg   )",
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- service section end --> */}
    </>
  );
};

export default Hero_section;
