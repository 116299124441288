import React, { useState } from "react";
const Footer = () => {
  const [email, setEmail] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Extract the email value from the event target
      const emailValue = e.target.elements.email.value;

      // Send the email data to the backend
      const response = await fetch('/subscribenews.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: emailValue }),
      });

      if (response.ok) {
        // Display success message
        setSuccessMessage('Thank you for subscribing to our newsletter!');
        setEmail('');
      } else {
        // Handle error
        console.error('Failed to subscribe:', response.status);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <>
      <footer class="footer-wrapper footer-1 overflow-hidden">
        <div class="container">
          <div class="row">
            <div class="col-lg-3 col-md-4 col-sm-6 mt-40">
              <div class="single_footer">
                <h4>About us</h4>
                <p>
                  Empowering businesses through innovation, Mejona crafts
                  strategic technology solutions that unlock growth, enhance
                  efficiency, and captivate customers.
                </p>
                <ul class="icon-left">
                  <li>
                    <a href="https://www.instagram.com/mejona_technology">
                      <i className="fa-brands fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/mejona">
                      <i className="fa-brands fa-linkedin"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://facebook.com/Mejona.technology">
                      <i className="fa-brands fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.youtube.com/@Tech_Mejona">
                      <i className="fa-brands fa-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 mt-40">
              <div class="single_footer footer-link-2">
                <h4>Information</h4>
                <ul>
                  <li>
                    <a href="mailto:info@mejona.in" target="_blank">
                      info@mejona.in{" "}
                    </a>
                  </li>
                  <li>
                    <a href="https://wa.me/+918095990277" target="_blank">
                      Whatsapp (+91) 80959-90277
                    </a>
                  </li>
                  <li>
                    <p>
                      Mejona Technology LLP
                      <br />
                      Siddapura, Whitefield <br /> Bengaluru,
                      Karnataka 560066
                      <br />
                      India
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 mt-40">
              <div class="single_footer footer-link">
                <h4>More Link</h4>
                <ul>
                  <li>
                    <a href="/hr">Employee Dashboard</a>
                  </li>
                  <li>
                    <a href="/verify-interns">Verify Intern</a>
                  </li>
                  <li>
                    <a href="#">Testimonial</a>
                  </li>
                  <li>
                    <a href="#">Company Report</a>
                  </li>
                  <li>
                    <a href="https://www.mejona.com">Mejona Education</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 mt-40">
              <div class="single_footer footer-link">
                <h4>Hear from us</h4>
                <p>Stay updated with us.</p>
                <div className="newsletter_box">
                  <form onSubmit={handleSubmit}>
                    <input
                      type="email"
                      placeholder="Enter email id for newsletter"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      name="email" // Add this line
                    />
                    <button className="theme-btn btn-yellow" type="submit">
                      <i className="fa-regular fa-arrow-turn-down-left"></i>
                    </button>
                  </form>
                  {successMessage && <p>{successMessage}</p>}
                </div>
                <div class="footer-bootm-link">
                  <ul>
                    <li>
                      <a href="tandc">Terms of use</a> |{" "}
                    </li>
                    <li>
                      <a href="privacy">Privacy Policy</a>|{" "}
                    </li>
                    <li>
                      <a href="/career">career</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-8 offset-md-2">
              <div class="footer-bottom mb-40 text-center d-flex flex-column">
                <div class="footer-logo">
                  <a href="#">
                    <img
                      style={{ maxHeight: "80px" }}
                      src="assets/img/logo.png"
                      alt="footer-logo"
                    />
                  </a>
                </div>
                <p>
                  © Copyright 2023 <a href="/">Mejona Technology LLP</a> - All
                  Rights Reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="line-element-1 d-none d-lg-block">
          <img src="assets/img/svg-icon/line-footer-1.svg" alt="" />
        </div>
        <div class="line-element-2  d-none d-lg-block">
          <img src="assets/img/svg-icon/line-footer-2.png" alt="" />
        </div>
        <div class="dot-element-1  d-none d-lg-block">
          <img src="assets/img/svg-icon/footer-ector.svg" alt="" />
        </div>
        <div class="dot-element-2  d-none d-lg-block">
          <img src="assets/img/svg-icon/footer-ector.svg" alt="" />
        </div>
      </footer>
    </>
  );
};

export default Footer;
