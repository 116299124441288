import React from 'react'

const Feature = () => {
  return (
    <>
         <section className="service-wrapper service-4 section-padding overflow-hidden">
        <div className="line-1"><span></span></div>
        <div className="line-2"><span></span></div>
        <div className="line-3"><span></span></div>
        <div className="line-4"><span></span></div>
        <div className="container">
            <div className="row">
                <div className="col-md-8 offset-md-2">
                    <div className="section-title text-center wow fadeInUp animated" data-wow-delay="300ms">
                        <h6 className="text-center"> <span className="title-arrow"><i className="fa-solid fa-arrow-right"></i></span> Our Features</h6>
                        <h2>Comprehensive Services for Your Business Success</h2>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-3 col-md-4 col-sm-6 wow fadeInUp animated" data-wow-delay="300ms">
                    <div className="single_service mt-30">
                        <div className="card-img">
                            <img src="assets/img/ux.png" alt=""/>
                        </div>
                        <p>Craft dynamic websites reflecting your brand, ensuring a seamless online presence.</p>
                        <h5>Web Development</h5>
                        <span className="num">01</span>
                    </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 wow fadeInUp animated" data-wow-delay="500ms">
                    <div className="single_service mt-30">
                        <div className="card-img">
                            <img src="assets/img/mobile-development.png" alt=""/>
                        </div>
                        <p>Bring unique ideas to life with intuitive and engaging mobile applications across platforms.</p>
                        <h5>App Development</h5>
                        <span className="num">02</span>
                    </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 wow fadeInUp animated" data-wow-delay="700ms">
                    <div className="single_service mt-30">
                        <div className="card-img">
                            <img src="assets/img/digital-marketing.png" alt=""/>
                        </div>
                        <p>Strategize and execute targeted campaigns for maximum online visibility and impact.</p>
                        <h5>Digital Marketing</h5>
                        <span className="num">03</span>
                    </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 wow fadeInUp animated" data-wow-delay="1000ms">
                    <div className="single_service mt-30">
                        <div className="card-img">
                            <img src="assets/img/analysis.png" alt=""/>
                        </div>
                        <p>Provide industry insights, solving challenges with strategic and practical solutions.</p>
                        <h5>IT Consulting</h5>
                        <span className="num">04</span>
                    </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 wow fadeInUp animated" data-wow-delay="300ms">
                    <div className="single_service mt-30">
                        <div className="card-img">
                            <img src="assets/img/custom-solution.png" alt=""/>
                        </div>
                        <p>Tailor solutions to fit unique business needs, prioritizing adaptability and efficiency.</p>
                        <h5>Custom Solutions</h5>
                        <span className="num">05</span>
                    </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 wow fadeInUp animated" data-wow-delay="500ms">
                    <div className="single_service mt-30">
                        <div className="card-img">
                            <img src="assets/img/maintainance.png" alt=""/>
                        </div>
                        <p>Proactively monitor and update solutions, offering responsive support for seamless operation.</p>
                        <h5>Maintenance and Support</h5>
                        <span className="num">06</span>
                    </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 wow fadeInUp animated" data-wow-delay="700ms">
                    <div className="single_service mt-30">
                        <div className="card-img">
                            <img src="assets/img/seo.png" alt=""/>
                        </div>
                        <p>Boost online visibility with effective SEO strategies and compelling content creation.</p>
                        <h5>SEO & Content Writing</h5>
                        <span className="num">07</span>
                    </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 wow fadeInUp animated" data-wow-delay="1000ms">
                    <div className="single_service mt-30">
                        <div className="card-img">
                            <img src="assets/img/business_consultancy.png" alt=""/>
                        </div>
                        <p>Offer expert advice and guidance to optimize business strategies and operations.</p>
                        <h5>Business Consultancy</h5>
                        <span className="num">08</span>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default Feature