import React from 'react'

const Servicelist = () => {
  return (
    <>

<section className="contact-wrapper overflow-hidden content-1">
        {/* <!-- line 4 --> */}
        <div className="line-1"><span></span></div>
        <div className="line-2"><span></span></div>
        <div className="line-3"><span></span></div>
        <div className="line-4"><span></span></div>

        <div className="row align-items-end">
            <div className="col-md-8 wow fadeInLeft animated" data-wow-delay="300ms">
                <div className="single-contact-left ps-2">
                    <div className="section-title mb-30 p-0">
                        <h6> <span className="title-arrow"><i className="fa-solid fa-arrow-right"></i></span>Unlocking Business Growth</h6>
                        <h2>Empowering Business Growth with Innovative Solutions</h2>
                    </div> 
                    <div className="link-custome">
                        <ul>
                            <li><img src="assets/img/svg-icon/b-icon.svg" alt=""/><a href="#">Expand Your Reach with Effective Advertising and Marketing Strategies</a></li>
                            <li><img src="assets/img/svg-icon/b-icon.svg" alt=""/><a href="#">Transform Your Ideas into Reality with Expert IT Consulting and Evaluation</a></li>
                            <li><img src="assets/img/svg-icon/b-icon.svg" alt=""/><a href="#">Harness the Power of Data-Driven Insights with Innovative Analytical Digital Solutions</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="col-md-4 wow fadeInRight animated" data-wow-delay="300ms">
                <div className="single-contact-right ps-2 bg-center bg-cover" style={{backgroundImage: 'url(assets/img/contact.png)'}}></div>
            </div>
        </div>
        <div className="contact-img">
            <img src="assets/img/blog/contact-2.png" alt=""/>
        </div>
        <div className="shape-element">
            <img src="assets/img/svg-icon/arrow.svg" alt=""/>
        </div>
    </section>

    </>
  )
}

export default Servicelist