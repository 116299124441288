import React from "react";

const MainSection = () => {
    const style = {
        padding:'10px',
        listStyleType:'',
        important: 'true'
    };
  return (
    <>
      <section className="blog-wrapper overflow-hidden section-top blog-1">
        <div className="line-1">
          <span></span>
        </div>
        <div className="line-2">
          <span></span>
        </div>
        <div className="line-3">
          <span></span>
        </div>
        <div className="line-4">
          <span></span>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="row">
                <div className="col-md-12 mt-30">
                  <div className="single_blog bg-transparent">
                    <div
                      className="blog-featured-thumb bg-cover blog3-thumb"
                      style={{
                        backgroundImage: "url(assets/img/blog/blog1.png)",
                      }}
                    ></div>
                    <div className=" mt-40 mb-50">
                      <h2>Terms of Use</h2>
                      <div className="post-top-meta d-flex flex-wrap align-items-center"></div>
                      <p>
                        Welcome to Mejona Technology! These terms and conditions
                        outline the rules and regulations for the use of our
                        website.
                      </p>
                      <p>
                        By accessing this website, we assume you accept these
                        terms and conditions in full. Do not continue to use our
                        website if you do not agree to all the terms and
                        conditions stated on this page.{" "}
                      </p>
                      <h3 id="inte">Intellectual Property Rights</h3>
                      <p>
                        Unless otherwise stated, Mejona Technology and/or its
                        licensors own the intellectual property rights for all
                        materials on this website.
                      </p>
                      <h3>Restrictions</h3>
                      <p>You are specifically restricted from:</p>

                      <ul style={{ listStyleType: 'disc', important: 'true' }}>
                        <li style={style}>
                          Publishing any website material in any other media;
                        </li>
                        <li style={style}>
                          Selling, sublicensing, and/or otherwise
                          commercializing any website material;
                        </li>
                        <li style={style}>
                          Publicly performing and/or showing any website
                          material without explicit written permission;
                        </li>
                        <li style={style}>
                          Using this website in any way that is or may be
                          damaging to this website;
                        </li>
                        <li style={style}>
                          Using this website in any way that impacts user access
                          to this website;
                        </li>
                        <li style={style}>
                          Using this website contrary to applicable laws and
                          regulations, or in any way that may cause harm to the
                          website, or to any person or business entity;
                        </li>
                        <li style={style}>
                          Engaging in any data mining, data harvesting, data
                          extracting, or any other similar activity in relation
                          to this website;
                        </li>
                        <li style={style}>
                          Using this website to engage in any advertising or
                          marketing.
                        </li>
                      </ul>
                    
                      <h3>Disclaimer</h3>
                      <p>
                        The materials on Mejona Technology's website are
                        provided on an 'as is' basis. Mejona Technology makes no
                        warranties, expressed or implied, and hereby disclaims
                        and negates all other warranties including, without
                        limitation, implied warranties or conditions of
                        merchantability, fitness for a particular purpose, or
                        non-infringement of intellectual property or other
                        violation of rights.
                      </p>
                      <h3>Limitation of Liability</h3>
                      <p>
                        In no event shall Mejona Technology or its suppliers be
                        liable for any damages arising out of the use or
                        inability to use the materials on Mejona Technology's
                        website, even if Mejona Technology or a Mejona
                        Technology authorized representative has been notified
                        orally or in writing of the possibility of such damage.
                      </p>
                      <h3>
                      Indemnification
                      </h3>
                      <p>
                      You hereby indemnify to the fullest extent Mejona Technology from and against any and/or all liabilities, costs, demands, causes of action, damages, and expenses arising in any way related to your breach of any of the provisions of these terms.
                      </p>
                      <h3>
                      Severability
                      </h3>
                      <p>
                      If any provision of these terms is found to be invalid under any applicable law, such provisions shall be deleted without affecting the remaining provisions herein.
                      </p>
                      <h3>
                      Variation of Terms
                      </h3>
                      <p>
                      Mejona Technology is permitted to revise these terms at any time as it sees fit, and by using this website, you are expected to review these terms on a regular basis.
                      </p>
                      <h3>Entire Agreement</h3>
                      <p>
                      These terms constitute the entire agreement between Mejona Technology and you in relation to your use of this website and supersede all prior agreements and understandings.
                      </p>
                      <p>
                      For any further information, please contact us at admin@mejona.in.
                      </p>
                    </div>
                  </div>
                </div>

               
              </div>
            </div>
            <div className="col-lg-4">
              <div className="row">
                <div className="col-12 mt-30">
                  <div className="blog-right">
                    {/* <h4>Search Here</h4> */}
                    {/* <div className="newsletter_box about-right">
                      <form action="#">
                        <input
                          type="email"
                          placeholder="Enter Your Keyword..."
                          required=""
                        />
                        <button className="search-btn" type="submit">
                          <i className="fa-solid fa-magnifying-glass"></i>
                        </button>
                      </form>
                    </div> */}
                    <div className="service-list">
                      <h4>JUMP TO</h4>
                      <button>
                        <span className="icon">
                          <i className="fa-solid fa-angle-right"></i>
                        </span>{" "}
                        <a href="#inte">Intellectual Property Rights</a>
                      </button>
                      <button>
                        <span className="icon">
                          <i className="fa-solid fa-angle-right"></i>
                        </span>
                        Restrictions
                      </button>
                      <button>
                        <span className="icon">
                          <i className="fa-solid fa-angle-right"></i>
                        </span>
                        Disclaimer
                      </button>
                      <button>
                        <span className="icon">
                          <i className="fa-solid fa-angle-right"></i>
                        </span>
                        Limitation Of Liability
                      </button>
                      <button>
                        <span className="icon">
                          <i className="fa-solid fa-angle-right"></i>
                        </span>
                        Indemnification
                      </button>
                      <button>
                        <span className="icon">
                          <i className="fa-solid fa-angle-right"></i>
                        </span>
                        Severability
                      </button>
                      <button>
                        <span className="icon">
                          <i className="fa-solid fa-angle-right"></i>
                        </span>
                        Variation Of Terms
                      </button>
                      <button>
                        <span className="icon">
                          <i className="fa-solid fa-angle-right"></i>
                        </span>
                        Entire Agreement
                      </button>
                    </div>
                    <div className="icons-element">
                      <h4>Social icon</h4>
                      <div className="icons">
                       
                        <a href="https://www.instagram.com/mejona_technology">
                          <i className="fa-brands fa-instagram"></i>
                        </a>
                        <a href="https://www.linkedin.com/company/mejona">
                          <i className="fa-brands fa-linkedin"></i>
                        </a>
                        <a href="https://facebook.com/Mejona.technology">
                          <i className="fa-brands fa-facebook"></i>
                        </a>
                        <a href="https://www.youtube.com/@Tech_Mejona">
                          <i className="fa-brands fa-youtube"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 mt-30">
                  <div
                    className="blog-bottom-right bg-center bg-cover"
                    style={{ backgroundImage: "url(assets/img/video.png)" }}
                  >
                    <div className="content">
                      <h4>Innovate with confidence</h4>
                      <span>We deliver tech solutions that fuel growth</span>
                      <h2>25+</h2>
                      <strong>Satisfied Clients</strong>
                      <a href="discover" className="theme-btn style-1">
                        Discover More +
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MainSection;
