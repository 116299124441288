import React from "react";
import './style.scss';

const Form = () => {
  return (
    <>
    <div className="container">
    <br/>
    <br/>
      <div className="form-container">
      <div className="form-row">
        <div className="form-group">
          <label htmlFor="fullName">Full Name</label>
          <input type="text" id="fullName" name="fullName" placeholder="Enter your full name" />
        </div>
        <div className="form-group">
          <label htmlFor="mobileNumber">Mobile Number</label>
          <input type="text" id="mobileNumber" name="mobileNumber" placeholder="Enter your mobile number" />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input type="email" id="email" name="email" placeholder="Enter your email" />
        </div>
        <div className="form-group">
          <label htmlFor="pinCode">Pin Code</label>
          <input type="text" id="pinCode" name="pinCode" placeholder="Enter your pin code" />
        </div>
      </div>
    </div>
    </div>
    </>
  );
};

export default Form;
