import React from 'react'

const BusinessPartner = () => {
  return (
    <>
         <div className="com_logo_slider section-padding">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="section-title text-center">
                        <h6 className="sub-title" > <span className="title-arrow-logo"><i className="fa-solid fa-arrow-right"></i></span> Our business partners</h6>
                    </div>
                </div>
            </div>
            <div className="slider_logo mt-30 owl-carousel">
                <div className="single_slider">
                    <img src="assets/img/company-logo/Upwork.png" alt="upwork" />
                </div>
                <div className="single_slider">
                    <img src="assets/img/company-logo/bark.png" alt="Bark" />
                </div>
                <div className="single_slider">
                    <img src="assets/img/company-logo/google.png" alt="google" />
                </div>
                <div className="single_slider">
                    <img src="assets/img/company-logo/microsft.png" alt="microsft" />
                </div>
                <div className="single_slider">
                    <img src="assets/img/company-logo/meta.png" alt="facebook" />
                </div>
                <div className="single_slider">
                    <img src="assets/img/company-logo/hostinger.png" alt="hostinger" />
                </div>
                <div className="single_slider">
                    <img src="assets/img/company-logo/razorpay.png" alt="razorpay" />
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default BusinessPartner