import React, { useState } from "react";

const Contact_section = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [phone, setPhone] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const apiUrl = "submit_appointment.php";
    
    // Format the form data as URL-encoded string
    const formData = new URLSearchParams();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("message", message);
    formData.append("phone", phone);

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded", // Change content type
        },
        body: formData.toString(), // Convert form data to string
      });

      if (response.ok) {
        // Handle success
        alert("Thank you for contacting us! We'll get back to you soon.");
        window.location.reload();
      } else {
        // Handle error
        alert("Failed to submit form");
        window.location.reload();
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Failed to submit form");
      window.location.reload();
    }
  };


  return (
    <>
      <section class="contact-wrapper contact-2 section-top">
        <div class="line-1">
          <span></span>
        </div>
        <div class="line-2">
          <span></span>
        </div>
        <div class="line-3">
          <span></span>
        </div>
        <div class="line-4">
          <span></span>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="location-map section-bottom">
              <iframe
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15553.202566236821!2d77.7335815!3d12.9526041!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae13e88adf290f%3A0xc76da055c3e67dc!2sMejona%20Technology%20LLP!5e0!3m2!1sen!2sin!4v1714162670415!5m2!1sen!2sin"
      width="600"
      height="450"
      style={{ border: 0 }}
      allowFullScreen=""
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    ></iframe>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="contact-left">
                <div class="section-title p-0">
                  <h6>
                    {" "}
                    <span class="title-arrow">
                      <i class="fa-solid fa-arrow-right"></i>
                    </span>
                    Get In Touch
                  </h6>
                  <h2>Contact Us.</h2>
                </div>
                <div class="contact-info">
                  <a href="tell:+918095990277">
                    <img src="assets/img/svg-icon/b-icon.svg" alt="" /> +91
                    809-5990-277
                  </a>
                  <a href="mailto:contact@mejona.in">
                    <img src="assets/img/svg-icon/b-icon.svg" alt="" />{" "}
                    contact@mejona.in
                  </a>
                  <a href="#">
                    <img src="assets/img/svg-icon/b-icon.svg" alt="" />Whitefield, Bengaluru-560066, Karnataka
                  </a>
                  <a href="#">
                    <img src="assets/img/svg-icon/b-icon.svg" alt="" />Reg. Address: Newton
                    Garden, Jagdeo Path, Patna-800014.
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-6 res-column">
              <div class="contact-right">
                <div class="img">
                  <img src="assets/img/svg-icon/arrow.svg" alt="" />
                </div>
                <div class="section-title p-0">
                  <h6>
                    {" "}
                    <span class="title-arrow">
                      <i class="fa-solid fa-arrow-right"></i>
                    </span>{" "}
                    Let us get in contact with you.
                  </h6>
                  <h2>
                    Empower your brand with Mejona's growth-driven solutions.
                  </h2>
                </div>
                <form onSubmit={handleSubmit}>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group my-2">
                        <label htmlFor="name">Your Name</label>
                        <input
                          required
                          type="text"
                          name="name"
                          class="form-control"
                          id="name"
                          placeholder="Enter Your Name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                    </div>

                    <div class="col-sm-6">
                      <div class="form-group my-2">
                        <label htmlFor="email">Your Mail</label>
                        <input
                          required
                          type="email"
                          name="email"
                          class="form-control"
                          id="email"
                          placeholder="example@gmail.com"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group my-2">
                        <label htmlFor="phone">Phone no.</label>
                        <input
                          required
                          type="text"
                          name="phone"
                          class="form-control"
                          id="phone"
                          placeholder="Enter Contact No."
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </div>
                    </div>
                    <div class="col-sm-8">
                      <div class="form-group my-2">
                        <label htmlFor="message">Message</label>
                        <textarea
                          required
                          name="message"
                          id="message"
                          cols="8"
                          rows="4"
                          placeholder="Briefly describe your interest or inquiry."
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                        ></textarea>
                        <small>
                          * Ready to ignite your success? Contact us 24/7 or
                          fill out the form for a free consultation.
                        </small>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group mt-40">
                        <button type="submit" class="theme-btn style-1">
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact_section;
