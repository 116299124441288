import React from 'react'
import Header from './header'
import Hero from './tandc_components/hero'
import MainSection from './tandc_components/MainSection'
import Footer from './footer'

const TandC = () => {
  return (
    <>
        <Header/>
        <Hero/>
        <MainSection/>
        <Footer/>
    </>
  )
}

export default TandC