import React from 'react';
import Header from './header';
import BlogContent from './blog_details_components/BlogContent';
import BusinessPartner from './homepage_components/businesss_partner';
import Footer from './footer';
import Hero from './blog_details_components/Hero';

const BlogDetails = () => {
  return (
    <>
        <Header/>
        <Hero/>
        <BlogContent/>
        <BusinessPartner/>
        <Footer/>
        
    </>
  )
}

export default BlogDetails