import React from "react";
import Preloader from "./preloader";
import Header from "./header";
import HeroSection from "./homepage_components/hero_section";
import BusinessPartner from "./homepage_components/businesss_partner";
import About from "./homepage_components/about";
import WhatWeDo from "./homepage_components/whatwedo";
import ContactMessage from "./homepage_components/contactMessage";
import Servicelist from "./homepage_components/servicelist";
import Footer from "./footer";
import WhatsAppIcon from "./WhatsAppIcon";

const Homepage = () => {
  return (
    <>
      {/* <Preloader /> */}
      <Header />
      <HeroSection/>
      <BusinessPartner/>
      <About/>
      <WhatWeDo/>
      <ContactMessage/>
      <Servicelist/>
      <Footer/>
      <WhatsAppIcon/>z
      <Preloader/>

    </>
  );
};

export default Homepage;
