import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet";

import Homepage from "./components/homepage";
import Service from "./components/service";
// import Blog from "./components/blog";
import Contact from "./components/contact";
import About from "./components/about";
import TandC from "./components/TandC";
import Privacy from "./components/Privacy";
import BlogDetails from "./components/BlogDetails";

import NotFound from "./components/NotFound";
import Form from "./components/testing/form";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Helmet>
                <title>Mejona Technology </title>
                <meta
                  name="description"
                  content="Mejona Technology is a trusted technology solutions provider, offering web development, software solutions, and IT consulting services."
                />
              </Helmet>
              <Homepage />
            </>
          }
        />
        <Route
          path="/about"
          element={
            <>
              <Helmet>
                <title>About Us | Mejona Technology</title>
                <meta
                  name="description"
                  content="Learn more about our company and mission."
                />
              </Helmet>
              <About />
            </>
          }
        />
        <Route
          path="/service"
          element={
            <>
              <Helmet>
                <title>Our Services | Mejona Technology</title>
                <meta
                  name="description"
                  content="Explore our range of services and offerings."
                />
              </Helmet>
              <Service />
            </>
          }
        />
        {/* <Route
          path="/blog"
          element={
            <>
              <Helmet>
                <title>Blog | Mejona Technology</title>
                <meta
                  name="description"
                  content="Read the latest articles and updates on our blog."
                />
              </Helmet>
              <Blog />
            </>
          }
        /> */}
        <Route
          path="/contact"
          element={
            <>
              <Helmet>
                <title>Contact Us | Mejona Technology</title>
                <meta
                  name="description"
                  content="Get in touch with us for any inquiries or feedback."
                />
              </Helmet>
              <Contact />
            </>
          }
        />
         <Route
          path="/appointment"
          element={
            <>
              <Helmet>
                <title>Contact Us | Mejona Technology</title>
                <meta
                  name="description"
                  content="Get in touch with us for any inquiries or feedback."
                />
              </Helmet>
              <Contact />
            </>
          }
        />

        <Route
          path="/tandc"
          element={
            <>
              <Helmet>
                <title>Terms and Conditions | Mejona Technology</title>
                <meta
                  name="description"
                  content="Read our terms and conditions for using our services."
                />
              </Helmet>
              <TandC />
            </>
          }
        />
        <Route
          path="/privacy"
          element={
            <>
              <Helmet>
                <title>Privacy Policy | Mejona Technology</title>
                <meta
                  name="description"
                  content="Learn about our privacy policy and data practices."
                />
              </Helmet>
              <Privacy />
            </>
          }
        />
        <Route
          path="/discover"
          element={
            <>
              <Helmet>
                <title>Discover | Mejona Technology</title>
                <meta
                  name="description"
                  content="Discover more about our offerings and services."
                />
              </Helmet>
              <About />
            </>
          }
        />
        <Route
          path="/blogdetail"
          element={
            <>
              <Helmet>
                <title>Blog Detail | Mejona Technology</title>
                <meta
                  name="description"
                  content="Read in-depth details about a specific blog post."
                />
              </Helmet>
              <BlogDetails />
            </>
          }
        />
  <Route
          path="/form"
          element={
            <>
              <Helmet>
                <title>Discover | Mejona Technology</title>
                <meta
                  name="description"
                  content="Discover more about our offerings and services."
                />
              </Helmet>
              <Form />
            </>
          }
        />

        {/* Add more routes with Helmet information as needed */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default App;
