import React from "react";

const History = () => {
  return (
    <>
      <section className="case-wrapper section-top case-1 overflow-hidden">
        <div className="line-1">
          <span></span>
        </div>
        <div className="line-2">
          <span></span>
        </div>
        <div className="line-3">
          <span></span>
        </div>
        <div className="line-4">
          <span></span>
        </div>
        <div className="container">
          {/* <div className="row">
                <div className="case-details">
                 <div className="case-details-bg bg-center bg-cover" style={{backgroundImage: 'url(assets/img/case-datils.png)'}}>
                     <div className="case-card  wow fadeInRight animated" data-wow-delay="300ms">
                        <div className="catagory">
                            <div className="icons">
                                <img src="assets/img/svg-icon/Icon_arrow.svg" alt=""/>
                            </div>
                            <div className="content">
                                <small>Clients:</small>
                                <h4>MD Towkib Tanvir (MG)</h4>
                            </div>
                        </div>
                        <div className="catagory">
                            <div className="icons">
                                <img src="assets/img/svg-icon/Icon_arrow.svg" alt=""/>
                            </div>
                            <div className="content">
                                <small>Category:</small>
                                <h4>Business Consulting</h4>
                            </div>
                        </div>
                        <div className="catagory">
                            <div className="icons">
                                <img src="assets/img/svg-icon/Icon_arrow.svg" alt=""/>
                            </div>
                            <div className="content">
                                <small>Timeframe:</small>
                                <h4>August 05, 2023</h4>
                            </div>
                        </div>
                        <div className="catagory">
                            <div className="icons">
                                <img src="assets/img/svg-icon/Icon_arrow.svg" alt=""/>
                            </div>
                            <div className="content">
                                <small>Website Link:</small>
                                <h4><a href="#">www.creativemela.com</a></h4>
                            </div>
                        </div>
                        <div className="icons-element mt-5">
                            <div className="icons justify-content-center">
                                <a href="#"><i className="fa-brands fa-facebook"></i></a>
                                <a href="#"><i className="fa-brands fa-twitter"></i></a>
                                <a href="#"><i className="fa-brands fa-instagram"></i></a>
                                <a href="#"><i className="fa-brands fa-linkedin"></i></a>
                            </div>
                        </div>
                     </div>
                 </div>
                </div>
             </div> */}
          <div className="row">
            <div className="col-lg-7">
              <div
                className="case-datils-content mt-40 mb-50 wow fadeInUp animated"
                data-wow-delay="300ms"
              >
                <div className="section-title p-0">
                  <h6>
                    {" "}
                    <span className="title-arrow">
                      <i className="fa-solid fa-arrow-right"></i>
                    </span>
                    Our History
                  </h6>
                  <h2>
                    Empowering Engineers: A Journey of Innovation and Growth
                    Since 2021
                  </h2>
                </div>
                <p>
                  Our journey at Mejona Technology LLP began in September 2021,
                  swiftly establishing ourselves as a key player in the IT
                  service domain. We officially registered with the Ministry of
                  Corporate Affairs in May 2022, marking a significant
                  milestone.
                </p>
                <p>
                  With a focus on empowering engineering professionals,
                  students, teachers, and engineers, we launched Mejona
                  Education, our flagship product. It serves as a comprehensive
                  platform offering solutions to engineering-related challenges,
                  featuring a rich repository of topics, questions, and answers.
                </p>
                <p>
                  As of January 2024, we've made substantial contributions to
                  the growth of engineering students, providing internships and
                  aiding in career development. Nurturing a thriving community
                  of more than 20 happy and satisfied clients reflects our
                  commitment to delivering quality services and fostering
                  success in the engineering domain.
                </p>
                <p>
                  At Mejona Technology LLP, we specialize in delivering a
                  diverse range of IT services tailored to the unique needs of
                  businesses. Our expertise spans web development, app
                  development, digital marketing, and IT consulting. Whether
                  you're a startup or an established enterprise, Mejona
                  Technology LLP is your trusted partner for all your IT-related
                  needs, empowering your business for sustained growth and
                  success.
                </p>
                {/* <h3>Our Comprehensive IT Solutions: Empowering Businesses with Innovation, Expertise, and Excellence</h3> */}
                <h4 style={{ color: "red", textAlign: "center" }}>
                  Our Services
                </h4>

                <ul>
                  <li>
                    {" "}
                    <span className="angle">
                      <i className="fa-solid fa-angle-right"></i>
                    </span>{" "}
                    <b style={{ color: "red" }}>Website Development::</b>{" "}
                    Immerse your business in the online realm with our expert
                    website development services. We craft dynamic, user-centric
                    websites that elevate your online presence and capture your
                    brand essence.
                  </li>
                  <li>
                    <span className="angle">
                      <i className="fa-solid fa-angle-right"></i>
                    </span>
                    <b style={{ color: "red" }}>App Development:</b> From
                    conceptualization to deployment, our app development team
                    creates innovative and user-friendly applications. We align
                    our solutions with your business objectives, ensuring
                    resonance with your audience.
                  </li>
                  <li>
                    <span className="angle">
                      <i className="fa-solid fa-angle-right"></i>
                    </span>
                    <b style={{ color: "red" }}>Digital Marketing: </b>Amplify
                    your online reach and engagement with our strategic digital
                    marketing solutions. We employ proven methodologies to
                    enhance visibility, drive traffic, and boost conversions,
                    ensuring a robust online presence.
                  </li>
                  <li>
                    <span className="angle">
                      <i className="fa-solid fa-angle-right"></i>
                    </span>
                    <b style={{ color: "red" }}>IT Consulting: </b> Leverage our
                    in-depth industry knowledge and technical proficiency for
                    comprehensive IT consulting services. We guide you through
                    the intricacies of technology to make informed decisions.
                  </li>
                  <li>
                    <span className="angle">
                      <i className="fa-solid fa-angle-right"></i>
                    </span>
                    <b style={{ color: "red" }}>Custom Solutions: </b>Tailoring
                    our services to your specific needs, we offer custom IT
                    solutions that align with your business goals and drive
                    efficiency.
                  </li>
                </ul>

                <h4 style={{ color: "red", textAlign: "center" }}>
                  Our Achievements
                </h4>
                <ul>
                  <li>
                    {" "}
                    <span className="angle">
                      <i className="fa-solid fa-angle-right"></i>
                    </span>{" "}
                    <b style={{ color: "red" }}>25+ Happy Clients:</b> Our
                    success is measured by the satisfaction of our clients. Join
                    a growing community of businesses that have experienced the
                    transformative power of Mejona Technology LLP.
                  </li>
                  <li>
                    <span className="angle">
                      <i className="fa-solid fa-angle-right"></i>
                    </span>
                    <b style={{ color: "red" }}>35+ Projects Completed:</b> With
                    a diverse portfolio of completed projects, we bring a wealth
                    of experience to each new endeavor. Trust us to turn your
                    ideas into digital success stories.
                  </li>
                  <li>
                    <span className="angle">
                      <i className="fa-solid fa-angle-right"></i>
                    </span>
                    <b style={{ color: "red" }}>2+ Years of Experience: </b>
                    Benefit from our seasoned expertise in the IT industry. We
                    leverage over two years of experience to deliver solutions
                    that align with industry trends and standards.
                  </li>
                  <li>
                    <span className="angle">
                      <i className="fa-solid fa-angle-right"></i>
                    </span>
                    <b style={{ color: "red" }}>24/7 Customer Support: </b> Your
                    business doesn't sleep, and neither do we. Our
                    round-the-clock customer support ensures that you receive
                    assistance whenever you need it.
                  </li>
                </ul>
                <h4 style={{ color: "red", textAlign: "center" }}>
                  Our Specialization
                </h4>
                <ul>
                  <li>
                    {" "}
                    <span className="angle">
                      <i className="fa-solid fa-angle-right"></i>
                    </span>{" "}
                    <b style={{ color: "red" }}>Prioritize Delivery Time:</b> We
                    understand the importance of timelines in the fast-paced
                    digital landscape. Our commitment to prioritizing delivery
                    time ensures that your projects are executed efficiently and
                    effectively.
                  </li>
                  <li>
                    <span className="angle">
                      <i className="fa-solid fa-angle-right"></i>
                    </span>
                    <b style={{ color: "red" }}>24/7 Customer Support: </b>Your
                    satisfaction is our priority. Our continuous customer
                    support ensures that your queries are addressed promptly,
                    contributing to a seamless and positive experience.
                  </li>
                  <li>
                    <span className="angle">
                      <i className="fa-solid fa-angle-right"></i>
                    </span>
                    <b style={{ color: "red" }}>Client Satisfaction: </b>
                    At Mejona Technology LLP, we measure our success by the
                    satisfaction of our clients. We prioritize understanding
                    your needs and delivering solutions that exceed
                    expectations.
                  </li>
                  <li>
                    <span className="angle">
                      <i className="fa-solid fa-angle-right"></i>
                    </span>
                    <b style={{ color: "red" }}>Post-Delivery Services: </b> Our
                    commitment doesn't end with project delivery. We provide
                    comprehensive post-delivery services, ensuring ongoing
                    support and maintenance for sustained success.
                  </li>
                </ul>
                <p>
                Experience the Mejona Technology LLP difference – where your success is our priority.
                </p>
              </div>
              {/* <div
                className="prevwe-elememt wow fadeInUp animated"
                data-wow-delay="300ms"
              >
                <div className="next-prev d-flex justify-content-between align-items-center">
                  <a href="#">
                    <img src="assets/img/svg-icon/icon-r.svg" alt="" /> PREVIOUS
                  </a>
                  <a href="#">
                    {" "}
                    NEXT <img src="assets/img/svg-icon/b-icon.svg" alt="" />
                  </a>
                </div>
              </div> */}
            </div>
            <div
              className="col-lg-5 mt-70 wow fadeInUp animated"
              data-wow-delay="300ms"
            >
              <div className="case-img">
                <img src="assets/img/case.png" className="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default History;
