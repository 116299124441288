import React from 'react'
import Header from "./header";
import Hero_section from './about_components/hero_section';
import About_section from './about_components/about_section';
import Footer from "./footer";
import History from './about_components/history';
import Preloader from './preloader';

const About = () => {
  return (
    <>
    {/* <Preloader/> */}
        <Header/>
        <Hero_section/>
        <About_section/>
        <History/>

        <Footer/>
    </>
  )
}

export default About