import React from 'react'

const BlogContent = () => {
  return (
    <>

<section className="blog-wrapper overflow-hidden section-top blog-1">

        <div className="line-1"><span></span></div>
        <div className="line-2"><span></span></div>
        <div className="line-3"><span></span></div>
        <div className="line-4"><span></span></div>
        <div className="container">
            <div className="row">
                <div className="col-lg-8">
                    <div className="row">
                        <div className="col-md-12 mt-30">
                            <div className="single_blog bg-transparent">
                                <div className="blog-featured-thumb bg-cover blog3-thumb" style={{backgroundImage: 'url(assets/img/blog/blog1.png)'}}></div>
                                <div className="case-datils-content mt-40 mb-50">
                                    <h3>Eko sapien, quis porttitor We’re a global stakeholder relations and partnership buildingipsum etilk.</h3>
                                    <div className="post-top-meta d-flex flex-wrap align-items-center">
                                        <div className="post-date">
                                            <span> by <a href="#">Themeforest</a></span>
                                            |
                                            <span><a href="#"> October 21, 2022</a></span>
                                        </div>
                                    </div>
                                    <p>In nibh felis, efficitur a dignissim a, posuere dignissim risus. Vestibulum ornare tellus tincidunt neque rhoncus, ut ultri Hincidunt. Maecenas condimentum in nisl at dignissim. Cras volutpat  augue ac iaculis. Integer ac metus id neq ehicula. In hac habitasse platea dictumst. Praesent rhoncus nisl sed dignissim posuere. Quisque eleifend commodo Sed convallis felis nec nunc posuere sodales.
                                        utrum orci felis malesuada lacus.</p>
                                    <p>Pellentesque dignissim felis rutrum lorem commodo, quis fermentum neque imperdiet. Pellentesqu habitant morbi senectus et netus et malesuada fames ac turpis egestas. Nullam quis dapibus dolor. Phasellus quis molestie tortor. Libero id felis. Curabitur tincidunt ornare efficitur. Morbi eget lectus ligula. Nam imperdiet ligula in neque facilisis </p>
                                    <div className="quite">
                                        <div className="quite-icons">
                                            <div className="icons">
                                                <i className="fa-solid fa-quote-left"></i>
                                            </div>
                                        </div>
                                        <p>Tempus massa ac arcu sollicitudin sollicitudin Vivamus neque urna, iaculis et orci id to your hospital Phasellus ac consequat turpis, sit amet fermentum nulla. Donec dignissim augue nunc.</p>
                                    </div>
                                    <p>Praesent consequat, velit in iaculis efficitur, dui diam elementum quam, vitae ultrices metus libero id felis. Curabitur tincidunt ornare efficitur. Morbi eget lectus ligula. Nam imperdiet ligula in neque facilisis pharetra. Mauris viverra metus ac ex consectetur rhoncus. Nullam tellus turpis, auctor non risus vitae, posuere lacinia sapien.</p>
                                    <p>Libero id felis. Curabitur tincidunt ornare efficitur. Morbi eget lectus ligula. Nam imperdiet ligula in neque facilisis viverra metus ac ex consectetur rhoncus. Nullam tellus turpis, auctor non risus vitae, posuere sapien.</p>
                                    <ul>
                                        <li>Opting for a single-plate meal as opposed to a buffet, or sticking to light.</li>
                                        <li>Entertainment “ instead of hiring a professional, look for one to his time.</li>
                                        <li>“ limit swag to small items that can be quantity at a low cost keychains.</li>
                                    </ul>
                                    <p>Libero id felis. Curabitur tincidunt ornare efficitur. Morbi eget lectus ligula. Nam imperdiet ligula in neque facilisis viverra metus ac ex consectetur rhoncus. Nullam tellus turpis, auctor non risus vitae, posuere sapien.</p>
                               </div>
                               <div className="prevwe-elememt">
                                    <div className="next-prev d-flex justify-content-between align-items-center">
                                        <a href="#"><img src="assets/img/svg-icon/icon-r.svg" alt=""/> PREVIOUS</a>
                                        <a href="#"> NEXT <img src="assets/img/svg-icon/b-icon.svg" alt=""/></a>
                                    </div>
                               </div>
                            </div>
                        </div>
                        <div className="col-md-12 mt-30">
                            <div className="comment-widget">
                                <h4 className="mb-30">02 Comments</h4>
                                <div className="comment-item mt-20 mb-20">
                                    <div className="user-img">
                                        <img src="assets/img/man1.png" alt=""/>
                                    </div>
                                    <div className="user-content">
                                        <strong>Monsur Rahman Lito</strong>
                                        <p>Phasellus ac consequat turpis, sit amet fermentum nulla. Donec dignissim augue nunc. Praesent bibendum erat ac lectus molestie lobortis.</p>
                                        <button>Reply </button>
                                    </div>
                                </div>
                                <hr/>
                                <div className="comment-item comment-item-reply mt-20 mb-20">
                                    <div className="user-img">
                                        <img src="assets/img/blog/comment-reply.png" alt=""/>
                                    </div>
                                    <div className="user-content">
                                        <strong>Monsur Rahman Lito</strong>
                                        <p>Phasellus ac consequat turpis, sit amet fermentum nulla. Donec dignissim augue nunc. Praesent bibendum erat ac lectus molestie lobortis.</p>
                                        <button>Reply </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 mt-30">
                            <div className="live-comment-widget">
                                <h4 className="mb-30">Leave a Comment</h4>
                                <form action="#">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group my-2">
                                                <label for="name">Your Name</label>
                                                <input type="text" name="text" className="form-control" id="name"  placeholder="Enter Your Name"/>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group my-2">
                                                <label for="mail">Your Mail</label>
                                                <input type="email" name="mail" className="form-control" id="mail"  placeholder="infoflex@info.com"/>
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="form-group my-2">
                                                <label for="lable">Message</label>
                                               <textarea name="massage" id="lable" cols="8" rows="6" placeholder="Write your message"></textarea>
                                               <small>* Call us 24/7  or fill out the form below to receive a free.</small>
                                            </div>
                                        </div>
                                       <div className="col-12">
                                        <div className="form-group mt-40">
                                            <button type="submit" className="theme-btn style-1">Post Comments +</button>
                                        </div>
                                       </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="row">
                        <div className="col-12 mt-30">
                            <div className="blog-right">
                                <h4>Search Here</h4>
                                <div className="newsletter_box about-right">
                                    <form action="#">
                                        <input type="email" placeholder="Enter Your Keyword..." required=""/>
                                        <button className="search-btn" type="submit"><i className="fa-solid fa-magnifying-glass"></i></button>
                                    </form>
                                </div>
                                <div className="service-list">
                                    <h4>Services List</h4>
                                    <button><span className="icon"><i className="fa-solid fa-angle-right"></i></span> UX strategy</button>
                                    <button><span className="icon"><i className="fa-solid fa-angle-right"></i></span>Information architecture</button>
                                    <button><span className="icon"><i className="fa-solid fa-angle-right"></i></span>Prototyping</button>
                                    <button><span className="icon"><i className="fa-solid fa-angle-right"></i></span>Wireframing</button>
                                    <button><span className="icon"><i className="fa-solid fa-angle-right"></i></span>UI Design</button>
                                    <button><span className="icon"><i className="fa-solid fa-angle-right"></i></span>Figma Design</button>
                                </div>
                                <div className="icons-element">
                                    <h4>Social icon</h4>
                                    <div className="icons">
                                        <a href="#"><i className="fa-brands fa-facebook"></i></a>
                                        <a href="#"><i className="fa-brands fa-twitter"></i></a>
                                        <a href="#"><i className="fa-brands fa-instagram"></i></a>
                                        <a href="#"><i className="fa-brands fa-linkedin"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mt-30">
                            <div className="blog-bottom-right bg-center bg-cover" style={{backgroundImage: 'url(assets/img/video.png)'}}>
                                <div className="content">
                                    <h4>Business Growth Exposé</h4>
                                    <span>Your Success Provide Best .</span>
                                    <h2>1.5k+</h2>
                                    <strong>Satisfied Clients</strong>
                                    <a href="#" className="theme-btn style-1">Discover More +</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </section>

    </>
  )
}

export default BlogContent