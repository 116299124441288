import React from 'react'

const About = () => {
  return (
    <>

<section className="about-wrapper overflow-hidden home-1-about section-bottom">
        {/* <!-- line 4 --> */}
        <div className="line-1"><span></span></div>
        <div className="line-2"><span></span></div>
        <div className="line-3"><span></span></div>
        <div className="line-4"><span></span></div>

        <div className="container">
            <div className="row">
                <div className="col-lg-6 pe-2 pe-md-3 pe-lg-5 wow fadeInLeft animated" data-wow-delay="300ms">
                    <div className="about-left position-relative">
                        <div className="abt_cmpny">
                            <div className="line-short"><span></span></div>
                            <div><h3 className="title">About Mejona</h3></div>
                        </div>
                        <div className="about-left-img">
                            <img src="assets/img/about/about-1.png" alt=""/>
                        </div>
                        <div className="about-left-img-2">
                            <img src="assets/img/about/about-2.png" alt=""/>
                        </div>
                        <div className="box-about box-about-2">
                            <div className="box-ten">
                                <h5>Your Success is Our Mission</h5>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-6 ps-2 ps-md-3 ps-lg-5 wow fadeInRight animated" data-wow-delay="300ms">
                    <div className="about-right pt-5 pt-lg-0">
                        <div className="section-title ps-0 pe-0">
                            <h6> <span className="title-arrow"><i className="fa-solid fa-arrow-right"></i></span> About Mejona</h6>
                            <h2>Your Trusted Partner in Digital Transformation</h2>
                            <p>Mejona Technology is a leading software company that provides a comprehensive suite of services to help businesses achieve their digital transformation goals. We are a team of experienced and passionate professionals who are committed to delivering exceptional results. We specialize in web development, software development, mobile app development, and digital marketing. Our clients appreciate our dedication, expertise, and commitment to quality.</p>
                        </div>
                        
                    </div>
                    <div className="row">
                        <div className="col-sm-6 wow fadeInUp animated" data-wow-delay="300ms">
                            <div className="about-text-right">
                                <h5><i className="fa-regular fa-arrow-up"></i>Web Development</h5>
                                <div className="about-btn mt-30">
                                    <p> Elevate your online presence with our expert web
                      development services, delivering dynamic and user-friendly
                      websites tailored to meet your business goals.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 wow fadeInUp animated" data-wow-delay="300ms">
                            <div className="about-text-right">
                                <h5><i className="fa-regular fa-arrow-up"></i>IT Consulting</h5>
                                <p className="mt-30">We offer expert IT consulting services to optimize your business processes and enhance overall efficiency.</p>
                                {/* <div className="price-reng mt-30">
                                    <h5>Content Strategy 90%</h5>
                                    <div className="progress">
                                        <div className="progress-value" style={{ width: '90%' }}></div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    </>
  )
}

export default About