import React from 'react'

const Hero_section = () => {
  return (
    <>

<section className="hero-wrapper hero-1 hero-3 hero-4 hero-border overflow-hidden">
        <div className="hero-widget">
            <div className="hero-single-slider bg-center bg-cover" style={{backgroundImage: 'url(assets/img/hero/woman_about.jpg)'}}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12">
                            <div className="hero-content">
                                <h1 className="breadcrumb__title">About Us</h1>
                                <div className="bread_c">
                                    <p className="breadcrumb">
                                       <a href="index-2.html">Home /&nbsp;</a>  About us
                                    </p>
                                 </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="breadcrumb-shape wow fadeInUpBig">
                    <img src="assets/img/svg-icon/video-shape.svg" alt=""/>
                </div>
                <div className="shape-left wow fadeInLeft">
                    <img src="assets/img/shap/shap.png" alt="shape"/>
                </div>
                <div className="shape-bottom-2 wow fadeInUpBig">
                    <img src="assets/img/shap/shap-2.png" alt="shape"/>
                </div>
                <div className="breadcrumb-right-element wow fadeInRightBig">
                    <img src="assets/img/svg-icon/right-2.svg" alt=""/>
                </div>
                <div className="top-element wow fadeInDown">
                    <img src="assets/img/svg-icon/shape-3.svg" alt=""/>
                </div>
              
            </div>
        </div>
    </section>

    </>
  )
}

export default Hero_section