import React from 'react';
import './WhatsAppIcon.css';

const WhatsAppIcon = () => {
  const handleWhatsAppRedirect = () => {
    // Replace the following number with your desired WhatsApp number
    const whatsappNumber = '+918095990277';
    const predefinedMessage = 'I would like to inquire about the pricing for the creation of a professional business website.';
    const whatsappUri = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(predefinedMessage)}`;
    window.open(whatsappUri, '_blank');
  };

  return (
    <div className="whatsapp-icon" onClick={handleWhatsAppRedirect}>
      <i className="fab fa-whatsapp"></i>
    </div>
  );
};

export default WhatsAppIcon;